@import "../../../styles/variables";

.pagination {
  font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;
  justify-content: center;
  margin-top: 1rem;
}

.page-item {
  margin: 0 .2rem;

  &.active > .page-link {
    background-color: $primary-black;
    border-color: $primary-black;
  }
}

.page-link {
  color: $primary-black;
  background-color: $primary-white;
  border: 2px solid $primary-black;
  border-radius: 0 !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background-color: $primary-black;
    color: $primary-white;
    border-color: $primary-black;
  }
}