@import "../../../styles/variables";

.Toastify {
    &__toast {
        &--warning {
            background-color: $toast-color-warning;
        }

        &--success {
            background-color: $toast-color-success;
        }

        &--info {
            background-color: $toast-color-info;
        }

        &--error {
            background-color: $toast-color-error;
        }
    }
}

.custom-toast {
    &--body {
        display: flex;
        align-items: center;
    }

    &--icon {
        padding: 0 15px 0 5px;
    }

    &--msg {
        padding: 10px 0 10px 0;
    }
}