@import "../../../styles/variables";
.accordion {
    &--header {
        cursor: pointer;
        .icon {
            transition: all .3s;
            &.rotate {
                transform: rotate(180deg);
            }
        }
    }

    &--body {
        max-height: 300px;
        opacity: 1;
        transition: all .6s;
        overflow: hidden;
        //overflow-y: auto;

        &.collapsed {
            max-height:0;
            opacity:0;
        }
    }
}