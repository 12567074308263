
//colors

$primary-black:          #000000;
$primary-orange:         #f16e00;
$primary-white:          #ffffff;
$primary-green:          #50BE87;
$primary-gray:           #a69ba3;
$primary-red:            #dc3545;

$toast-color-success:           #50be87;
$toast-color-warning:           #DB7C26;
$toast-color-error:             #be131d;
$toast-color-info:              #4bb4e6;

$primary-black-half-opacity:    rgba($primary-black, .5);


@font-face {
    font-family: HelvNeueOrange55;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/HelvNeue55_W1G.eot'),
    url('/fonts/HelvNeue55_W1G.woff') format('woff'),
    url('/fonts/HelvNeue55_W1G.woff2') format('woff2'),
    url('/fonts/HelvNeue55_W1G.ttf') format('truetype'),
    url('/fonts/HelvNeue55_W1G.svg') format('svg');
}

@font-face {
    font-family: HelvNeueOrange75;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/HelvNeue75_W1G.eot'),
    url('/fonts/HelvNeue75_W1G.woff') format('woff'),
    url('/fonts/HelvNeue75_W1G.woff2') format('woff2'),
    url('/fonts/HelvNeue75_W1G.ttf') format('truetype'),
    url('/fonts/HelvNeue75_W1G.svg') format('svg');}