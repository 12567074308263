@import "../../styles/variables";

.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: $primary-black;
    color: $primary-white;
    transition: all 0.3s;

    @media (max-width: 768px) {
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &-header {
        padding: 20px;
    }

    ul {
        &.components {
            padding: 20px 0;
        }

        li {

            &.active {
                a {
                    color: $primary-orange;
                }
            }

            a {
                padding: 10px;
                font-size: 1.1em;
                display: block;
                color: $primary-white;
                text-decoration: none;
                transition: all .3s;

                &:hover {
                    color: $primary-orange;
                }

                &.dropdown-toggle::after {
                    float: right;
                    margin: 10px;
                }

                .dropdown-custom-toggle {
                    float: right;
                    margin: .25rem;
                    transition: transform .3s ease;

                    &.rotate {
                        transform: rotate(180deg);
                    }
                }
            }

            ul {
                overflow: hidden;
                list-style: none;
                display: block;
                max-height: 500px;
                opacity:1;
                padding-left: 10px;
                transition: all 0.5s;

                &.hidden {
                    max-height: 0;
                    opacity:0;
                    padding-left: 30px;
                }

                li {
                    a {
                        font-size: 0.9em;
                        color: $primary-white;
                        text-decoration: none;

                        &:hover {
                            color: $primary-orange;
                        }

                        &.active {
                            color: $primary-orange;
                        }
                    }
                }
            }
        }
    }

    &.hidden {
        margin-left: -250px;
    }

    &-close {
        color: $primary-gray;
        display: none;

        @media (max-width: 768px) {
            display: block;
        }

        &:hover ,&:active {
            color: $primary-gray;
        }
    }
}