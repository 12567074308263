@import "../../../styles/variables";

.page-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &__progress {
        height: 3px;
        background-color: $primary-orange;
    }

    &__infinity {
        position: relative;
        width: 100%;
        height: 3px;
        z-index: 0;

        &-bar {
            content: '';
            display: inline;
            width: 0;
            height: 100%;
            position: absolute;
            left: 50%;
            text-align: center;

            &:nth-child(1) {
                background-color: $primary-orange;
                animation: loading 1.7s linear infinite;
            }

            &:nth-child(2) {
                background-color: $primary-black;
                animation: loading 1.7s linear .85s infinite;
            }
        }
    }

    @keyframes loading {
        from {
            width: 0;
            left: 50%;
            z-index: 100;
        }

        33.3333% {
            width: 100%;
            left: 0;
            z-index: 10;
        }

        to {
            width: 100%;
            left: 0;
        }
    }
}