@import "../../styles/variables";

.modal {
    display: block;

    &-content {
        border-radius: 0;
    }

    &-header {
        border-bottom: none;
        padding: 2rem 2rem 1rem 2rem;
    }

    &-body {
        padding: 1rem 2rem 1rem 2rem;
    }

    &-footer {
        border-top: none;
        padding: 1rem 1rem 2rem 1rem;
    }

    &-backdrop {
        background-color: $primary-black;
        opacity: 0.8;
    }

    &-overflow--visible {
        overflow: visible !important;
    }
}