.modal {
    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 150ms ease-in;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transition: opacity 300ms ease-out;
        }
    }
}

.router {
    &-enter {
        position: absolute;
        opacity: 0;
        transform: scale(0.9);

        &-active {
            opacity: 1;
            transform: translateX(0);
            transition: opacity 300ms, transform 300ms;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 300ms, transform 300ms;
        }
    }
}

.toggle {
    &-enter {
        opacity: 0;
        transform: scale(0.9);

        &-active {
            opacity: 1;
            transform: translateX(0);
            transition: opacity 300ms, transform 300ms;
        }
    }

    &-exit {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 1;

        &-active {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 300ms, transform 300ms;
        }
    }
}

.spinner {
    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 200ms ease-in;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transition: opacity 200ms ease-out;
        }
    }
}
