@import "styles/variables";

.bg {
    &-dark {
        background-color: $primary-black !important;
    }
}

.text {
    &-light {
        color: $primary-white;
    }
    &-gray {
        color: $primary-gray;
    }

    &-size {
      &-3 {
          font-size: 1.75rem;
      }
    }
}

.border {
    &--gray {
        border: 2px solid $primary-gray;
    }
}

body {
    font-family: HelvNeueOrange55, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;
}

a, a:hover {
    color: inherit;
    text-decoration: none;
}
