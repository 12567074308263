@import "src/styles/variables";

$btns: (
    primary: (
        bgColor: $primary-orange,
        bgColorHover: $primary-black,
        bgColorDisabled: $primary-gray,
        color: $primary-white,
        colorHover: $primary-white,
        colorDisabled: $primary-white,
        borderColor: $primary-orange,
        borderColorHover: $primary-black,
        borderColorDisabled: $primary-gray,
    ),
    secondary: (
        bgColor: $primary-white,
        bgColorHover: $primary-black,
        bgColorDisabled: $primary-gray,
        color: $primary-black,
        colorHover: $primary-white,
        colorDisabled: $primary-white,
        borderColor: $primary-black,
        borderColorHover: $primary-black,
        borderColorDisabled: $primary-gray,
    ),
    secondary-inverted: (
        bgColor: $primary-black,
        bgColorHover: $primary-white,
        bgColorDisabled: $primary-gray,
        color: $primary-white,
        colorHover: $primary-black,
        colorDisabled: $primary-white,
        borderColor: $primary-black,
        borderColorHover: $primary-black,
        borderColorDisabled: $primary-gray,
    ),
    navbar: (
        bgColor: $primary-black,
        bgColorHover: $primary-black,
        bgColorDisabled: $primary-black,
        color: $primary-gray,
        colorHover: $primary-white,
        colorDisabled: $primary-white,
        borderColor: $primary-gray,
        borderColorHover: $primary-white,
        borderColorDisabled: $primary-white,
    ),
);

.btn {
    border-radius: 0 !important;
    font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;

    &:focus, &:active {
        box-shadow: none !important;
    }

    @each $type, $type-values in $btns {
        &--#{$type} {
            background: map-get($type-values, bgColor);
            color: map-get($type-values, color);
            border: 2px solid map-get($type-values, borderColor);

            &:hover {
                background: map-get($type-values, bgColorHover);
                color: map-get($type-values, colorHover);
                border: 2px solid map-get($type-values, borderColorHover);
            }

            &:disabled {
                background: map-get($type-values, bgColorDisabled);
                color: map-get($type-values, colorDisabled);
                border: 2px solid map-get($type-values, borderColorDisabled);
                cursor: default;
            }
        }
    }
}