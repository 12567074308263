@import "src/styles/variables";

.navbar {
    padding-left: 0 !important;
    &-dark {
        .navbar-nav {
            .nav-link {
                color: $primary-white !important;
                font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;

                &:hover {
                    color: $primary-orange !important;
                }
            }
        }
    }

    &-toggler {
        display: block !important;
        &:focus, &:active {
            outline: none !important;
        }
    }
}
