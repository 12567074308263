@import "../../../styles/variables";

.form {
    &-group {
        font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;
        color: $primary-gray;
        transition: all 0.3s;

        &:focus-within {
            label {
                color: $primary-black;
            }

            .input-group-append {
                border-top: 2px solid $primary-black;
                border-right: 2px solid $primary-black;
                border-bottom: 2px solid $primary-black;
            }
        }

        .input-group-append {
            border-top: 2px solid $primary-gray;
            border-right: 2px solid $primary-gray;
            border-bottom: 2px solid $primary-gray;
            transition: all 0.3s;

            .input-group-text {
                background-color: inherit;
                border-radius: inherit;
                border: unset;
            }
        }
    }

    &-control {
        $this: &;

        border: 2px solid $primary-gray;
        color: $primary-gray;
        box-shadow: none !important;
        border-radius: 0;
        transition: all 0.3s;
        font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;
        padding: 0.5rem 0.625rem 0.5rem 0.625rem;
        height: calc(1.5em + .75rem + 4px);

        &:not(:last-child) {
            border-right: unset;
        }

        &:focus, &:active {
            border: 2px solid $primary-black;
            color: $primary-black;

            &:not(:last-child) {
                border-right: unset;
            }
        }

        &:read-only {
            background-color: inherit;
            border: 2px solid $primary-gray;
            color: $primary-gray;
            pointer-events:none;
        }

        &.textarea {
            height: unset;
        }
    }
}

.custom {
    &-control {
        transition: all 0.3s;

        &-input {
            z-index: 1 !important; /*This is important for redux-form functionality*/
            width: 1.25rem !important;
            height: 1.25rem !important;
            top: .15rem !important;

            &:not(:disabled):checked~.custom-control-label {
                color: $primary-black !important;

                &:before {
                    border: 2px solid $primary-orange !important;
                    background-color: $primary-orange !important;
                }
            }
        }

        &-label {
            font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;
            color: $primary-gray !important;

            &:before {
                box-shadow: none !important;
                border: 2px solid $primary-gray !important;
                background-color: $primary-white !important;
                color: $primary-white !important;
                border-radius: 0 !important;
                width: 1.25rem !important;
                height: 1.25rem !important;
                top: .15rem !important;
            }
            &:after {
                width: 1.25rem !important;
                height: 1.25rem !important;
                top: .15rem !important;
            }
        }
    }
}

.invalid-feedback {
    display: block !important;
}

//input.is-invalid,textarea.is-invalid,.form-control:not(:focus).is-invalid~.input-group-append {
input.is-invalid,textarea.is-invalid,.form-control.is-invalid~.input-group-append {
    border-color: $primary-red !important;
    background-image: none !important;
    color: $primary-red !important;
}

::placeholder {
    color: $primary-gray !important;
    opacity: .65 !important;
}