.table {
    thead {
        th {
            border-top: none;
            font-family: HelvNeueOrange75, HelvNeueOrange55, sans-serif;
        }
    }

    td {
        vertical-align: unset;
    }

    .header-center {
        vertical-align: middle;
    }
}